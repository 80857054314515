<template>
  <span v-if="false" />
</template>

<script setup lang="ts">
/**
 * This component is used to handle the redirects for the editorial pages.
 * It will check if the current page has a redirect and redirect the user to the new page.
 * If no redirect is found, it will throw a 404 error.
 */

const runtimeConfig = useRuntimeConfig();
const { editorialApiBaseUrl } = runtimeConfig.public;
const requestURL = useRequestURL();

interface Redirect {
  id: number;
  dateCreated: string;
  dateUpdated: string;
  uid: string;
  siteId: number;
  associatedElementId: number;
  enabled: number;
  redirectSrcUrl: string;
  redirectSrcUrlParsed: string;
  redirectSrcMatch: string;
  redirectMatchType: string;
  redirectDestUrl: string;
  redirectHttpCode: number;
  priority: number;
  hitCount: number;
  hitLastTime: string;
}

interface ApiResponse {
  success: boolean;
  retourRedirects?: Redirect[];
}

interface TransformRedirect {
  redirectTo: string | false;
  redirectHttpCode: number;
}

/**
 * Parse the URL to get the current path + search params
 */
const urlPath = ref<string>('');
urlPath.value = parseUrl(requestURL.toString());

/**
 * Instead of doint multiple requests to the API, we can get all the redirects in one request
 */
const { data } = await useFetch(`/ecom-api/editorial/get-redirects`, {
  method: 'POST',
});
const response = data.value as ApiResponse;

/**
 * Use the redirects and the current URL to figure out correct redirect URL
 */
const { redirectTo, redirectHttpCode } = transformRedirectUrl(
  response?.retourRedirects,
  urlPath.value
);

/**
 * Redirect the user to the new page
 */

if (redirectTo) {
  const redirectToPath = redirectTo
    .replace(editorialApiBaseUrl, '')
    .replace('https://firstvet.com', '');
  await navigateTo(redirectToPath, {
    redirectCode: redirectHttpCode,
  });
} else {
  throw createError({
    statusCode: 404,
    statusMessage: 'Page Not Found',
    fatal: true,
  });
}

/**
 * Parse the URL to get the current path + search params
 */

function parseUrl(requestUrl: string): string {
  try {
    const urlObject = new URL(requestUrl);
    return urlObject.pathname + urlObject.search;
  } catch (error) {
    console.error('Invalid URL:', requestUrl);
    return ''; // or handle the error as needed
  }
}

/**
 * Transform the redirect URL to the new URL
 */

function transformRedirectUrl(
  retourRedirects: Redirect[] | undefined,
  currentUrl: string
): TransformRedirect {
  if (retourRedirects === undefined || retourRedirects.length === 0) {
    return {
      redirectTo: false,
      redirectHttpCode: 301,
    };
  }

  const retourRedirect = findMostRelevantRedirect(
    urlPath.value,
    retourRedirects
  );

  if (retourRedirect === undefined) {
    return {
      redirectTo: false,
      redirectHttpCode: 301,
    };
  }
  if (retourRedirect.redirectMatchType === 'regexmatch') {
    const pattern = new RegExp(retourRedirect.redirectSrcUrl);
    const match = currentUrl.match(pattern);

    if (match) {
      const transformedUrl =
        retourRedirect.redirectDestUrl.replace('??', '?') + (match[1] || '');
      return {
        redirectTo: transformedUrl,
        redirectHttpCode: retourRedirect.redirectHttpCode,
      };
    }
  }
  return {
    redirectTo: retourRedirect.redirectDestUrl,
    redirectHttpCode: retourRedirect.redirectHttpCode,
  };
}

/**
 * Find the most relevant redirect for the current URL
 */

function findMostRelevantRedirect(
  currentUrl: string,
  retourRedirects: Redirect[]
): Redirect | undefined {
  // Direct Match
  let relevantRedirect = retourRedirects.find(
    (redirect) => redirect.redirectSrcUrl === currentUrl
  );

  // Ends with
  if (!relevantRedirect) {
    relevantRedirect = retourRedirects.find((redirect) =>
      redirect.redirectSrcUrl.endsWith(currentUrl)
    );
  }

  // Direct Match without Search Params
  if (!relevantRedirect) {
    relevantRedirect = retourRedirects.find((redirect) =>
      redirect.redirectSrcUrl.endsWith(currentUrl.split('?')[0])
    );
  }

  return relevantRedirect;
}
</script>
